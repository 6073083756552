import { createApp } from 'vue';
import App from './app';
import router from '@/router';
import '@/assets/css/tailwind.css';
import '@/assets/css/global.scss';
//@ts-ignore
import preview from '@haomo/vue-preview-imgs';
import { i18n } from '@/core/i18n';
import 'maz-ui/css/main.css';
// 埋点日志库
import {
  tiny,
  logger,
  EventType,
  PageExceptionType,
  getSystem
} from '@haomo/fe-log';
import { getEnv } from './utils/env';

// 初始化
tiny.init(
  {
    appId: getEnv() === 'production' ? 'driveGPT' : 'driveGPT_test', //上报来源应用
    appType: 'web', // 上报来源应用类型
    showSpiderDebugLog: false, //  是否在console里显示底层spider的日志，默认false
    autoHook: true, // 是否自动hook页面的交互行为并采集日志，例如pageView，click，request，route等操作，默认false
    autoCollectRequest: true, // 是否采集页面发起的请求的性能日志，默认false
    hashRoute: false, // 配置是否按照hash路由的模式解析页面地址，默认false
    offlineMode: false // 是否是离线日志模式，默认false。离线模式下，会禁用实时上报机制，采用用户触发上报
  },
  {
    // 上报至毫末云日志服务的相关配置
    ajaxConfig: {
      getAppConfig() {
        return {
          appId: getEnv() === 'production' ? 'driveGPT' : 'driveGPT_test',
          appKey: '777e90acd20d4d10c26c792c3ef96622'
        };
      },
      onlyReportDefaultEvent: false, // 是否只上报默认预设的日志事件类型给日志中心，默认：false
      useBeacon: true, // 如果运行环境支持beacom api的话，是否优先使用beaconApi上报。默认：true
      enableReportQueue: true, // 是否启用上报缓冲队列，默认开启；如果开启，会在上报队列中缓存日志数据，然后定时定量上报，默认每隔30秒或者每10条日志上报一次
      reportQueueCount: 10, // 上报缓冲队列的间隔数量
      reportQueueTiming: 30 // 上报缓存队列的间隔时间，单位：秒
    }
  }
);

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(preview);
app.mount('#app');
