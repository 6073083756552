import { createI18n } from 'vue-i18n';

const langs = import.meta.globEager('../../assets/i18n/lang/**/*.json');

const messages: Record<string, any> = {};

Object.entries(langs).forEach(([modPath, mod]) => {
  let modName = modPath;
  const slashLastIndex = modName.lastIndexOf('/');
  if (slashLastIndex > -1) {
    modName = modName.substring(slashLastIndex + 1);
  }
  modName = modName.split('.')[0];
  messages[modName] = mod.default;
});

const LANG_KEY = 'localLang';

export function setLang(langName: string) {
  if (messages[langName] === undefined) {
    throw new Error('Unsupported Language!');
  }
  localStorage.setItem(LANG_KEY, langName);
}

export function getLang() {
  const lang = localStorage.getItem(LANG_KEY);
  if (lang === undefined || lang === null) {
    return 'cn';
  } else {
    return lang;
  }
}

export const i18n = createI18n({
  legacy: false,
  locale: getLang(), // set locale
  fallbackLocale: 'cn', // set fallback locale
  messages
});

export function globalGetText(path: string) {
  const currentLang = getLang();
  const messageMap = messages[currentLang];
  const paths = path.split('.');
  let val = messageMap;
  for (let i = 0; i < paths.length; ++i) {
    val = val[paths[i]];
  }
  return val.toString();
}
