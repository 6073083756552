import {
  tiny,
  logger,
  EventType,
  PageExceptionType,
  getSystem
} from '@haomo/fe-log';

/**
 * 本地登录信息
 */
export interface ILoginInfo {
  /**
   * 用户id
   */
  userId: number;
  /**
   * 用户名称
   */
  userName: string;
  /**
   * 登录token
   */
  loginToken: string;
  /**
   * 公司名称
   */
  company: string;
}

interface ILoginInfoStore {
  info: ILoginInfo;
  timestamp: number;
}

const loginDuration = 3600 * 12 * 1000;

export const LOGIN_INGO_KEY = 'driveLoginInfo';

/**
 * 通过本地是否存在登录态数据来判断是否登录
 * @returns 是否登录
 */
export function isLogin(): boolean {
  return getLoginInfoFromLocal() ? true : false;
}

export function getLoginInfoFromLocal(): ILoginInfo | undefined {
  //#region 授权mock数据
  // return {
  //   userId: 100001,
  //   userName: 'zhangsan',
  //   loginToken: 'the_token'
  // };
  //#endregion
  const strLoginInfo = localStorage.getItem(LOGIN_INGO_KEY);
  if (strLoginInfo) {
    try {
      const loginInfoStore = JSON.parse(strLoginInfo) as ILoginInfoStore;
      const currentTime = new Date().getTime();
      if (currentTime - loginInfoStore.timestamp >= loginDuration) {
        removeLoginInfo();
        return undefined;
      } else {
        return loginInfoStore.info;
      }
    } catch {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function setLoginInfo(loginInfo: ILoginInfo) {
  localStorage.setItem(
    LOGIN_INGO_KEY,
    JSON.stringify({
      info: loginInfo,
      timestamp: new Date().getTime()
    })
  );
  // 记录日志
  tiny.login(loginInfo.userId.toString());
  // 记录公参，公司信息
  tiny.updateCommonParams({
    cmField1: loginInfo.company
  });
}

export function getTokenFromLocal() {
  const loginInfo = getLoginInfoFromLocal();
  return loginInfo ? loginInfo.loginToken : undefined;
}

export function removeLoginInfo() {
  localStorage.removeItem(LOGIN_INGO_KEY);
  // 清除日志
  tiny.logout();
  tiny.updateCommonParams({
    cmField1: ''
  });
}
