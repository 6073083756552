import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotFound',
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const goHome = () => {
      router.replace('/');
    };
    return () => (
      <div>
        <div>{t('request.error.404')}</div>
        <div onClick={goHome}>{t('noh.back2home')}</div>
      </div>
    );
  }
});
