import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

const ErrorPage = defineComponent({
  name: 'ErrorPage',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();

    const reload = () => {
      location.reload();
    };
    return () => (
      <div>
        <div>{props.message}</div>
        <div onClick={reload}>{t('errorPage.reloadTips')}</div>
      </div>
    );
  }
});

export function renderErrorPage(message: string) {
  return () => <ErrorPage message={message}></ErrorPage>;
}

export default ErrorPage;
