import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import NotFound from '@/pages/notFound';
import ErrorPage from '@/pages/error';
import { isLogin } from '@/core/auth';
import { checkMobile } from '@/utils/mobile';

const routes: RouteRecordRaw[] = [
  {
    name: 'portal',
    path: '/',
    component: () => import('@/pages/portal')
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/pages/home')
  },
  {
    name: 'noh',
    path: '/intelligent_drive',
    component: () => import('@/pages/noh')
  },
  {
    name: 'userProtocol',
    path: '/user_protocol',
    component: () => import('@/pages/protocol/user')
  },
  {
    name: 'privacyProtocol',
    path: '/privacy_protocol',
    component: () => import('@/pages/protocol/privacy')
  },
  {
    name: 'mobile',
    path: '/mobile',
    component: () => import('@/pages/mobile')
  },
  {
    name: 'error', // 错误页面
    path: '/error',
    component: ErrorPage
  },
  {
    name: 'notFound',
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (checkMobile()) {
    if (to.name !== 'mobile') {
      next({
        name: 'mobile',
        replace: true
      });
    } else {
      next();
    }
  } else {
    if (to.name === 'mobile') {
      next({
        name: 'portal',
        replace: true
      });
    } else {
      if (
        to.name === 'portal' ||
        to.name === 'userProtocol' ||
        to.name === 'privacyProtocol' ||
        to.name === 'noh'
      ) {
        if (isLogin()) {
          next();
          // if (to.query.src_url) {
          //   const route = router.resolve(
          //     decodeURIComponent(to.query.src_url as string)
          //   );
          //   next({
          //     ...route,
          //     replace: true
          //   });
          // } else {
          //   next({
          //     name: 'home',
          //     replace: true
          //   });
          // }
        } else {
          next();
        }
      } else {
        if (!isLogin()) {
          next({
            name: 'portal',
            // query: {
            //   ['src_url']: to.fullPath
            // },
            replace: true
          });
        } else {
          next();
        }
      }
    }
  }
});

export default router;
